.email, footer
{
    color: #F9F9F9;
}

.footer
{
    position: absolute;
    background: #304060;
    width: 100%;
    bottom: 0;
    height: 21rem;
}


.padding
{
    padding: 5%;
}
