.about-content
{
   color: #404040;
   text-align: justify;
}

h1
{
   color: #404040;
}

#about .text-body
{
    padding-left: 70px;
    padding-right: 70px;
    color: #505050;
}
