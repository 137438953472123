#app .text-body
{
    padding-left: 70px;
    padding-right: 70px;
    color: #505050
}

#page-container
{
    position: relative;
    min-height: 100vh;
}

#content-wrap
{
    padding-bottom: 25rem;
}
