#catventure
{
    padding-bottom: 50px;
    color: #555;
}

#catventure .center-block
{
    margin:auto;
    width:50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#catventure iframe.center-block
{
    margin:auto;
    width:70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#catventure .text-body
{
    padding-left: 70px;
    padding-right: 70px;
    color: #505050
}

#catventure .spacer
{
    padding-top: 20px;
}