#navbar-nav ul li a:hover
{
    color: #007bff !important;
}

.navbar-nav li a
{
    float: right;
    text-align: left;
}

.navbar
{
    background: #303030;
}