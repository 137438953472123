#marsrover
{
    padding-bottom: 50px;
    color: #555;
}

#marsrover .center-block
{
    margin:auto;
    width:50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#marsrover .text-body
{
    padding-left: 70px;
    padding-right: 70px;
    color: #505050
}

#marsrover .spacer
{
    padding-top: 20px;
}